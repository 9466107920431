const updatePendingFeeds = () => {
  const elements = Array.from(document.querySelectorAll('[data-feed-status="pending"]'))
  elements.forEach((element) => {
    const url = element.dataset.feedUrl
    setTimeout(() => {
      fetch(url).then((response) => {
        return response.json()
      }).then((data) => {
        element.outerHTML = data.html
        updatePendingFeeds()
      })
    }, 1000)
  })
}

updatePendingFeeds()
